.if {
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pointer:before {
  content: "\e906";
}
.metro:before {
  content: "\e905";
}
.plus:before {
  content: "\e900";
}
.check:before {
  content: "\e901";
}
.drive:before {
  content: "\e902";
}
.edit:before {
  content: "\e903";
}
.foot:before {
  content: "\e904";
}
.close:before {
  content: "\e90c";
}
