@import '../../../../styles/variables.scss';

.normal {
  margin-bottom: 10px; display: flex; align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  color: #fff; font-size: 16px; line-height: 20px; font-family: $u-m; text-align: left;
  width: 170px; flex-shrink: 0; padding-right: 10px;
}

.select {
  color: #fff; font-size: 16px; line-height: 20px; font-family: $u-m;
  padding: 2px 5px; width: 100%;
  background: transparent; border: solid 2px #fff; border-radius: 2px;
  cursor: pointer;
}

.options {
  color: #111111;
  cursor: pointer;
}
