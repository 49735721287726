.normal {
  position: fixed; width: 100%; height: 100%; left: 0; top: 0; z-index: 10;
  background: rgba(0,0,0,0.5);
  opacity: 0; pointer-events: none;
  transition: opacity 0.2s ease-out;
}

.opened {
  composes: normal;
  opacity: 1;
  pointer-events: auto;
}
