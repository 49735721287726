@import '../../../../styles/variables.scss';

@keyframes postingAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.normal {
  display: none; padding: 20px; position: fixed; width: 700px; height: 100vh; max-height: 800px; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 100;
  background: #4d4c7d; border-radius: 10px; box-shadow: 0 0 10px 10px rgba(0,0,0,0.3);
}

.open {
  composes: normal;
  display: block;
}

.overlay {
  display: none; position: absolute; left: 0; top: 0; width: 100%; height: 100%;
  background: rgba(0,0,0,.5); border-radius: 10px;

  &:after {
    content: '';
    display: block; width: 60px; height: 60px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
    border-left: solid 2px #fff; border-top: solid 2px #fff; border-right: solid 2px #fff; border-radius: 100px;
    animation: postingAnimation 1s linear infinite;
  }
}

.posting {
  composes: overlay;
  display: block;
}

.header {
  margin-bottom: 20px; display: flex; align-items: center; justify-content: space-between;
  height: 24px;
}

.title {
  color: #fff; font-size: 24px; font-family: $u-m;
  display: flex; align-items: center;
}

.isActive {
  margin-right: 15px;

  label {
    &:before {
      margin-right: 0; width: 24px; height: 24px;
    }
  }
}

.close {
  color: #fff; font-size: 24px; font-family: $u-r;
  background: none;
  cursor: pointer;

  &:hover {
    color: #f3c007;
  }
}

.section {
  max-height: calc(100% - 106px); margin-bottom: 30px; padding: 10px 15px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background: rgba(0,0,0,0.2); border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #f3c007; border-radius: 10px;
  }
}

.inputs, .checkboxes {
  margin-bottom: 30px; padding-right: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.checkboxes {
  display: flex; align-items: flex-start; flex-wrap: wrap;

  & > div {
    width: 50%; padding-right: 15px;
  }
}

.sectionTitle {
  color: #fff; font-size: 20px; font-family: $u-r; text-align: left;
  margin-bottom: 15px; width: 100%;
}
.footer {
  display: flex; align-items: center; justify-content: space-between;
  height: 30px;
}

.buttons {
  display: flex; align-items: center;
}

.li {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.button {
  color: #f3c007; font-size: 16px; font-family: $u-m; line-height: 20px;
  padding: 5px 10px;
  background: transparent; border: solid 2px #f3c007; border-radius: 4px;
  cursor: pointer;
}

.active {
  composes: button;
  color: #474273;
  background: #f3c007;
}
