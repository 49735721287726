@import '../../../../styles/variables.scss';

.normal {
  text-align: left;
}

.title {
  color: #fff; font-size: 24px; font-family: $u-r; line-height: 28px;
  margin-bottom: 15px;
}
