@import '../../styles/variables.scss';

.normal {
  padding: 40px 0 0;
}

.inner {
  text-align: left; padding-left: 96px;
  h2 {
		color: #fff; font-size: 30px; font-family: $u-m; line-height: 36px;
		margin-bottom: 25px;
	}

	h3 {
		color: #fff; font-size: 24px; font-family: $u-m; line-height: 28px; text-transform: uppercase;
		display: inline-block; margin-bottom: 15px;

    span {
      display: block; margin-bottom: 5px;

      &:last-child {margin-bottom: 0;}
    }
	}

	ol li, ul li, p {
		color: #fff; font-size: 18px; font-family: $u-r; line-height: 25px;
	}

	& > p {
		margin-bottom: 15px;

    &:first-of-type {
      margin-bottom: 30px;
    }
	}

	ul {
		margin-bottom: 20px;
		counter-reset: count1;

		& > li {

			&:before {
				counter-increment: count1;
				content: counters(count1,".")". ";
        font-family: $u-m;
			}

			& > h3 {
				margin-bottom: 5px;
			}

			ul, ol {
				margin-bottom: 0; padding-left: 22px;
			}

			ol {
				li {
					position: relative; padding-left: 15px;

					&:before {
						content: '';
						display: block; width: 5px; height: 5px; position: absolute; left: 0; top: 9px;
						background: #fff; border-radius: 50%;
					}
				}
			}

			ul, ol {
				padding-top: 5px;

				li {
					margin-bottom: 5px;
				}
			}

		}
	}

	& > ul {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		& > li {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				color: #fff; font-size: 24px; font-family: $u-m; line-height: 28px; text-transform: uppercase;
			}
		}
	}

	& > ol {
		counter-reset: count2;

		& > li {
			margin-bottom: 5px;

			&:before {
				counter-increment: count2;
				content: counters(count2,".")". ";
        font-family: $u-m;
			}
		}
	}
}
