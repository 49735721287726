@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.normal {
  width: calc(33.33333% - 20px); position: relative; margin: 10px; z-index: 10;
  background: #4d4c7d; border-radius: 10px;

  @include respond-to(md) {
    width: calc(50% - 20px);
  }
  @include respond-to(sm) {
    width: 100%; margin: 10px 0;
  }
}

.opacity {
  opacity: 0.5; filter: grayscale(1);
}

.edit {
  color: #f3c007; font-size: 20px; text-align: left;
  display: block; width: 40px; height: 40px; position: absolute; right: 0; top: 0; z-index: 5; padding-left: 12px;
  background: #4d4c7d; border-radius: 0 10px 0 10px;
  cursor: pointer;
}

.link {
  display: flex; flex-direction: column; padding-left: 60px; padding-bottom: 40px;

  @include respond-to(lg) {
    padding-left: 40px;
  }
  @include respond-to(xs) {
    padding-left: 20px; padding-bottom: 20px;
  }

  &:hover {
    .title {
      &:before {
        background: #f3c007;
      }
    }
    .figure {
      width: 380px;
      height: 290px; padding-top: 60px;
      box-shadow: 0 0 3px 2px rgba(0,0,0,0.2);

      @include respond-to(sm) {
        height: 100%; width: 100%;
        box-shadow: none;
      }
    }
    .figcaption {
      li {
        color: #fff;
      }
    }
    .icon {
      &:before {
        content: '\e906';
        font-size: 40px;
        position: relative; top: 4px;
      }
    }
  }
}

.image {
  width: 100%; height: 150px; position: relative;

  @include respond-to(sm) {
    height: 380px;
  }
  @include respond-to(659px) {
    height: 320px;
  }
  @include respond-to(559px) {
    height: 250px;
  }
  @include respond-to(xs) {
    height: 220px;
  }
}

.figure {
  position: relative; width: 100%; height: 100%; position: absolute; right: 0; bottom: 0; display: flex; align-items: flex-start;
  overflow: hidden; border-radius: 0 10px 0 10px;
  transition: width 0.2s ease-out, height 0.2s ease-out, padding 0.2s ease-out, box-shadow 0.2s ease-out;

  @include respond-to(sm) {
    padding-top: 60px;
    align-items: center;
  }
}

.figcaption {
  order: 0; width: 100%; padding: 10px; position: absolute; left: 0; top: 0; z-index: 1;
  background: #4d4c7d;

  @include respond-to(sm) {
    z-index: 3; padding: 10px 10px 10px 0;
  }

  ul {
    display: flex; align-items: center; flex-shrink: 0;
  }

  li {
    color: #fff; font-size: 18px; font-family: $u-r; white-space: nowrap; text-overflow: ellipsis; line-height: 30px;
    margin-right: 20px; display: inline-block; max-width: 240px; padding: 5px 0;
    overflow: hidden;
    transition: color 0.2s ease-out;

    i {
      margin-right: 8px;
    }

    &:first-child {
      position: relative; top: -2px;
    }
    &:last-child {
      margin-right: 0; flex-shrink: 0;
    }
  }
}

.circle {
  display: inline-block; width: 30px; height: 30px; margin-right: 8px; flex-shrink: 0; position: relative; top: 2px;
  border: solid 1px rgba(255,255,255,0.4); border-radius: 50%;
}

.metro {
  font-size: 12px;
  margin-right: 0 !important; position: relative; top: -3px;
}

.method {
  color: #f3c007 !important;

  small {
    font-size: 14px;
  }
}

.img {
  width: 100%; position: relative; z-index: 2;
}

.price {
  color: #474273; font-size: 20px; font-family: $u-b;
  padding: 15px; margin-bottom: 25px; align-self: flex-end; margin-top: -38px; z-index: 5; position: relative;
  background: #f3c007; border-radius: 0 10px 0 10px;

  span {
    font-size: 26px;
  }
}

.title {
  color: #fff; font-size: 22px; font-family: $u-r; line-height: 30px; text-align: left;
  margin-bottom: 30px; padding-top: 20px; position: relative;

  &:before {
    content: '';
    display: block; width: 62px; height: 4px; position: absolute; left: 0; top: 0;
    background: #fff; border-radius: 10px;
    transition: background 0.2s ease-out;
  }
}

.term {
  color: #ffffff; font-size: 18px; font-family: $u-r; line-height: 24px;
  height: 60px; padding-right: 30px; display: flex; align-items: center; align-self: flex-end;
  background: #454470; border-radius: 10px 0 0 10px;

  span {
    display: flex; align-items: center; justify-content: center; width: 60px; height: 60px; margin-right: 30px;
    background: #343053; border-radius: 10px;
  }
}

.icon {
  color: #f3c007; font-size: 32px; line-height: 60px;
}
