@import '../../../../styles/variables.scss';

.normal {
  display: none; position: relative; z-index: 3; padding: 15px 10px;
  background: #67669c; box-shadow: 0 0 10px 5px rgba(0,0,0,0.2); border-radius: 0 10px 10px 10px;
}

.active {
  composes: normal;
  display: block;
}
