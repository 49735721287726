@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.normal {
  padding-top: 80px;
}

.footer {
  display: flex; align-items: center; justify-content: center;

  @include respond-to(sm) {
    flex-direction: column;
  }
}

.copy {
color: #ffffff; font-size: 20px; font-family: $u-r; line-height: 30px;
}

.link {
  color: #f3c007; font-size: 20px; font-family: $u-r; line-height: 30px; text-decoration: underline;
  margin-left: 7px;

  &:hover {
    text-decoration: none;
  }
}
