@import '../../../styles/variables.scss';

@keyframes loginSpinner {
  0% {transform: translate(-50%, -50%) rotate(0);}
  100% {transform: translate(-50%, -50%) rotate(360deg);}
}

.normal {
  width: 400px; height: 260px; padding: 40px 20px 20px; display: none; flex-direction: column; align-items: center; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 100;
  background: #4d4c7d; border-radius: 10px; box-shadow: 0 0 10px 10px rgba(0,0,0,0.3);
  overflow: hidden;
}

.open {
  composes: normal;
  display: flex;
}

.spinner {
  composes: open;
  &:before {
    content: '';
    display: block;
    width: 60px; height: 60px; position: absolute; left: 50%; top: 50%;
    border-left: solid 2px #fff; border-top: solid 2px #fff; border-radius: 50%;
    animation: loginSpinner 0.6s linear infinite;
  }
  &:after {
    content: '';
    position: absolute; left: 0; top: 0; width: 100%; height: 100%;
    background: rgba(0,0,0,0.6);
  }
}

.close {
  color: #fff; font-size: 16px;
  display: flex; align-items: center; justify-content: center; width: 30px; height: 30px; position: absolute; right: 10px; top: 10px;
  background: transparent;
  cursor: pointer;

  &:hover {
    color: #f3c007;
  }
}

.title {
  color: #fff; font-size: 24px; font-family: $u-m;
  margin-bottom: 25px;
}

.inputWrap {
  display: flex; align-items: center; margin-bottom: 10px;
}

.label {
  color: #fff; font-size: 16px; font-family: $u-r; line-height: 20px;
  flex-shrink: 0; width: 80px;
}

.input {
  color: #fff; font-size: 16px; font-family: $u-r; line-height: 20px;
  padding: 2px 5px; width: 100%;
  background: transparent; border: solid 2px #fff; border-radius: 4px;
}

.error {
  color: #ff430b; font-size: 16px; font-family: $u-r; text-align: center;
  margin-bottom: 10px; display: none;
}

.activeError {
  composes: error;
  display: block;
}

.login {
  color: #f3c007; font-size: 16px; font-family: $u-m; line-height: 20px;
  padding: 5px 10px; margin-top: 20px;
  background: transparent; border: solid 2px #f3c007; border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: #474273;
    background: #f3c007;
  }
}
