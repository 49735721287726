@import '../../../styles/mixins.scss';

@keyframes loadingCards {
  0% {transform: translate(-50%, -50%) rotate(0deg);}
  100% {transform: translate(-50%, -50%) rotate(360deg);}
}

.normal {
  padding: 30px 0; min-height: calc(100vh - 280px);
}

.list {
  display: flex; align-items: flex-start; flex-wrap: wrap; padding-left: 71px; margin: -10px;

  @include respond-to(sm) {
    margin: -10px 0; padding-left: 40px;
  }
  @include respond-to(xs) {
    padding-left: 0;
  }

  &:hover {
    .cardHover {
      opacity: 0.6;

      @include respond-to(sm) {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.loading {
  display: block; width: 100%; height: 400px; position: relative;

  &:after {
    content: '';
    display: block; width: 60px; height: 60px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
    border-top: solid 2px #fff; border-right: solid 2px #fff; border-radius: 50%;
    animation: loadingCards 1s linear infinite;
  }
}

.cardHover {
  transition: 0.2s ease-out;
}

.addCard {
  display: block; width: calc(33.333333% - 20px); height: 400px; position: relative; margin: 10px;
  background: #343053; border: solid 4px #f3c007; border-radius: 10px;
  cursor: pointer;

  @include respond-to(md) {
    width: calc(50% - 20px);
  }
  @include respond-to(sm) {
    width: 100%; margin: 10px 0;
  }

  &:before,
  &:after {
    content: '';
    display: block; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
    background: #f3c007; border-radius: 10px;
  }

  &:before {
    width: 40px; height: 4px;
  }
  &:after {
    width: 4px; height: 40px;
  }
}
