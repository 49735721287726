@import '../../../../styles/variables.scss';

.normal {
  margin-bottom: 30px;
}

.list {
  display: flex; align-items: center;
}

.li {
  padding: 5px 10px; display: flex; align-items: center;
  background: #44406a; border-radius: 10px 10px 0 0;
}

.button {
  color: #fff; font-size: 20px; font-family: $u-r; line-height: 24px;
  z-index: 2; position: relative;
  background: transparent;
  cursor: pointer;
}

.activeLi {
  composes: li;
  z-index: 5;
  background: #67669c;
}

.label {
  display: inline-block; margin-right: 10px; width: 20px; height: 20px;
  cursor: pointer;

  &:after {
    content: '';
    display: inline-block; width: 20px; height: 20px;
    border: solid 2px #fff; border-radius: 2px;
  }
}

.activeLabel {
  composes: label;

  &:after {
    background: #f3c007; border-color: #f3c007;
  }
}

.input {
  display: none;
}
