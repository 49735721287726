@import '../../../../styles/mixins.scss';

.normal {
  width: 200px; height: 160px; margin-right: 20px; display: flex; align-items: center; justify-content: center; flex-shrink: 0; position: relative;
  border-radius: 8px;
  overflow: hidden;

  @include respond-to(sm) {
    width: 100%;
  }

  &:hover {
    .figcaption {
      opacity: 1;
    }
  }
}
.img {
  max-width: 90%; max-height: 90%;
}

.figcaption {
  position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 5; display: flex; align-items: center; justify-content: center;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.label {
  width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;
  cursor: pointer;
}
.input {
  display: none;
}
