@import '../../../../styles/variables.scss';

.normal {
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  color: #fff; font-size: 16px; font-family: $u-r; line-height: 20px;
}

.list {
  display: block;
}

.li {
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  color: #fff; font-size: 14px; font-family: $u-r; line-height: 18px;
  display: flex; align-items: center;
  cursor: pointer;

  &:before {
    content: '';
    display: block; width: 16px; height: 16px; margin-right: 10px;
    background: transparent; border: solid 2px #fff; border-radius: 2px;
  }
}

.activeLabel {
  composes: label;

  &:before {
    background: #f3c007; border-color: #f3c007;
  }
}

.input {
  display: none;
}
