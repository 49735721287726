@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.normal {

}

.header {
  position: relative; display: flex; justify-content: space-between; align-items: center; padding: 20px 25px 20px 96px;

  @include respond-to(md) {
    flex-direction: column; padding: 20px 15px 20px 54px;
  }
  @include respond-to(xs) {
    justify-content: flex-start; align-items: flex-start;
  }

  &:before {
    content: '';
    display: block; width: 72px; height: 96px; position: absolute; left: 0; top: -20px;
    background: url('/assets/img/bot.png') center center no-repeat; background-size: contain;

    @include respond-to(sm) {
      width: 40px;
    }
  }

  &:after {
    content: '';
    display: block; width: calc(100% - 71px); height: 100%; position: absolute; right: 0; top: 0; z-index: -1;
    background: #3e3a63; box-shadow: 0px 20px 20px 0px rgba(38, 38, 38, 0.15); border-radius: 10px;

    @include respond-to(sm) {
      width: calc(100% - 40px);
    }
  }
}

.title {
  color: #fff; font-size: 26px; font-family: $u-m; line-height: 30px; text-transform: uppercase;
  position: relative; padding-right: 20px;

  @include respond-to(md) {
    margin-bottom: 15px;
  }
  @include respond-to(xs) {
    font-size: 24px; text-align: left;
  }
}

.link {
  color: inherit;
}

.tm {
  font-size: 12px; position: absolute; right: 0px; top: -10px;

  @include respond-to(xs) {
    position: relative; top: -13px;
  }
}

.label {
  color: #ffffff; font-size: 20px; font-family: $u-r; line-height: 24px;
  display: flex; align-items: center;
  cursor: pointer;

  @include respond-to(xs) {
    text-align: left; font-size: 18px;
    align-items: flex-start;
  }
}

.checkbox {
  display: flex; align-items: center; justify-content: center; width: 24px; height: 24px; margin-left: 15px; flex-shrink: 0;
  background: #e7e6e7; border: solid 2px #b5b4b6; border-radius: 4px;
  cursor: pointer;

  @include respond-to(md) {
    order: -1; margin-left: 0; margin-right: 15px;
  }
}

.icon {
  color: #111111; font-size: 14px;
}

.input {
  display: none;
}
