@import '../../../../styles/variables.scss';

.normal {
  display: flex; align-items: center; height: 28px; margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  color: #fff; font-size: 16px; line-height: 20px; font-family: $u-m; text-align: left;
  width: 170px; flex-shrink: 0; padding-right: 10px;
}

.buttons {
  display: flex; align-items: center;
}

.label {
  color: #fff; font-size: 14px; line-height: 20px; font-family: $u-m; text-align: left;
  display: flex; align-items: center; margin-right: 30px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    display: block; width: 20px; height: 20px; margin-right: 10px;
    border: solid 2px #fff; border-radius: 50px;
  }
}

.active {
  composes: label;
  pointer-events: none;

  &:before {
    background: #f3c007; border-color: #f3c007;
  }
}

.input {
  display: none;
}
